'use client'
import PageLoading from '@/components/PageLoading'
import { Box } from '@mantine/core'
import { useRouter } from 'next/navigation'
import type React from 'react'
import { useEffect } from 'react'

const AppPage: React.FC = () => {
	const router = useRouter()
	useEffect(() => {
		router.push('/manager/home')
	}, [router.push])

	return (
		<Box h='100vh' w='100vw'>
			<PageLoading
				message='Sending you to CrewHero...'
				color='teal'
				textColor='teal'
				loaderType='dots'
			/>
		</Box>
	)
}

export default AppPage
